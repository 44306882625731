.flexContainer {
    display: flex;

    button {
        align-items: stretch;
    }
}

.umasGroupView {
    flex-shrink: 0;
}

.factorGroupView {
    flex-grow: 1;
}

.flexContainerFactor {
    display: flex;
    flex-wrap: wrap;
}

.flexContainer2 {
    display: flex;
    flex-direction: column;
}

.flexContainer2 > div {
    margin: 5px;
}

.card {
    align-items: stretch;
    border: solid;
    margin: 5px;
}

.card-header {
    background-color: #f1f1f1;
}

.card-body {
    background-color: #E0FFFF;
}

.flexContainer > div {
    background-color: #f1f1f1;
    margin: 0px;
    padding: 0px;
}

.supportSelect {
    width: 35%
}

.supportSelectLevel {
    width: 15%
}

.factorSelectType {
    width: 35%
}

.factorSelectLevel {
    width: 10%
}

.factorSelectRange {
    width: 10%
}

.flexContainerUserRow {
    display: flex;
}

.flexContainerUserRow ul {
    margin: 5px;
    padding: 0px;
}

ul {
    list-style-type: none;
}

.factorItem {
    margin: 5px;
    padding: 5px;
    border-width: thin;
    position: relative;
}

.factorItem-2 {
    border-style: none none solid none;
}

.factorItem-3 {
    border-style: solid;
}

.factorItem-w {
    background: #F5F5F5;
}

.factorItem-b {
    background: #87CEEB;
}

.factorItem-r {
    background: #FFB6C1;
}

.factorItem-g {
    background: #98FB98;
}

.mainC {
    margin: auto;
    padding: 2px;
    padding-top: 10px;
    width: 85%;
}

.tmpHeight {
    height: 450px;
}

.factorItem .tooltiptext {
    visibility: hidden;
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.factorItem:hover .tooltiptext {
    visibility: visible;
}
